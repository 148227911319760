import styled from 'styled-components';

export const ComponentWrapper = styled.a`
  background: var(--background);
  box-shadow: 0px .3rem .6rem rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  display: block;
  /* height: 76.2rem; */
  /* width: 25rem; */
  @media screen and (max-width: 768px) {
    height: auto; 
    max-width: 53rem;
    width: 100%;
  }
  img {
    background: black;
    display: block;
    height: auto;
    width: 100%;
  }
  .desktop {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  .mobile {
    display: none;
  }
`;