import React from 'react';
import PropTypes from 'prop-types';
import { ComponentWrapper } from './VerticalPromoCard.css';

const VerticalPromoCard = (props) => {
  const { desktop_image, mobile_image, url } = props;
	return (
    <ComponentWrapper href={url} className="c-VerticalPromoCard">
      <img className="desktop" src={desktop_image} alt="Desktop Promo" />
      <img className="mobile" src={mobile_image} alt="Mobile Promo" />
    </ComponentWrapper>
	); 
};

VerticalPromoCard.propTypes = {
  desktop_image: PropTypes.string.isRequired,
  mobile_image: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
}

export default VerticalPromoCard;