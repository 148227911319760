import styled from 'styled-components';

const PageWrapper = styled.div`
  padding: 5.3rem 0;
  @media only screen and (max-width: 768px) {
    padding-top: 0;
  }
  .c-VerticalPromoCard {
    margin-top: 1rem;
  }
  .center-wrapper {
    background-color: var(--background);
    box-shadow: 0px 0.3rem 0.6rem rgba(0,0,0,0.16);
    color: var(--text);
    margin: 0 auto;
    max-width: 146.6rem;
    padding: 5rem;
  }
  .page-controls {
    box-sizing: border-box;
    padding-left: 1rem;
    margin: 0 auto;
    max-width: 149rem;
    width: 100%;
  }
  .current-selector {
    display: flex;
    div {
      background-color: #F1F1F1; 
      color: #707070;
      cursor: pointer;
      font-weight: bold;
      padding: 1rem 1.5rem;
      text-transform: uppercase;
    }
    .selected {
      color: var(--background);
      background-color: var(--text);
    }
  }
  .current{
    margin-right: 0.5rem;
  }
  .phase-selector {
    display: flex;
    margin: 4rem 0;
    .phase {
      color: var(--text);
      cursor: pointer;
      font-size: 1.4rem;
      font-weight: bold;
      margin-right: 4rem;
      position: relative;
      text-transform: uppercase;
      &.selected:after {
        background-color: var(--primary);
        bottom: -1rem;
        content: '';
        height: 3px;
        left: 0;
        position: absolute;
        width: 100%;
        @media only screen and (max-width: 768px) {
          bottom: 0.4rem;
        }
      }
    }
    @media only screen and (max-width: 768px) {
      overflow-x:scroll;
      min-height: 4rem;
    }
  }
  h1 {
    font-size: 2.4rem;
    text-transform: uppercase;
  }
  .component-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .c-ScheduleDateBlock {
    max-width: 100rem;
  }
  .c-StandingsCard {
    margin-top: 3.5rem;
    max-width: 41.4rem;
  }
  .standings-subtext{
    font-size:1.2rem;
    margin-left:1rem;
    margin-top:3.5rem;
  }
  .doubleStandings {
    display:flex;
    flex-direction:column;
    flex-grow:1;
  }
  .doubleStandings .c-StandingsModule:not(:first-child){
    margin-top:3.5rem;
  }
`;

export default PageWrapper;