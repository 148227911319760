import React, { Component } from 'react';
// import { Link } from 'gatsby';
import withContext from 'store/withContext';
import StandingsModule from 'components/Global/StandingsModule';
import VerticalPromoCard from 'components/Home/VerticalPromoCard';

import PageWrapper from '../styles/standings.css';
// import { isThisSecond } from 'date-fns';

class Standings extends Component {

  constructor(props) {
    super(props);

    this.state = {
      phase: undefined,
      initalLoadComplete: false
    }
  }

  filterPhase = (date) => {
    if (date.phase === this.state.phase) {
      return true;
    }
  }

  updatePhase = (phase) => {
    if (phase) {
      this.setState({phase: phase.title});
      this.props.context.actions.updateStandingsPhase(phase.id)
    }
    else {
      this.props.context.actions.updateStandingsPhase();
    }
  }
  setInitialPhase = ()=>{
    let phaseID=null
    for(let p in this.props.context.schedule.phases){

      if(this.props.context.schedule.phases[p].title==process.env.GATSBY_LEAGUE_PHASE){
        phaseID=this.props.context.schedule.phases[p].id
        break
      }
    }
    if(phaseID){
      this.props.context.actions.updateStandingsPhase(phaseID)
    }
  }
  componentDidMount() {
    if (this.props.context.schedule) {
      //Additional filter rule for Season 8 Phase 1 Group stage
      this.setState({
        phase: process.env.GATSBY_LEAGUE_PHASE,
        initalLoadComplete: true
      }); 
      //Get the initial phase standings based on GATSBY_LEAGUE_PHASE
      this.setInitialPhase()
      //Original Rule Below
      /*this.setState({
        phase: this.props.context.schedule.phases[this.props.context.schedule.phases.length - 1].title,
        initalLoadComplete: true
      }); */
    }
    
    
  }

  componentDidUpdate(prevProps) {
    //Additional filter rule for Season 8 Phase 1 Group stage
    if ((this.props.context.schedule !== prevProps.context.schedule) && !this.state.initialLoadComplete) {
      this.setState({
        phase: process.env.GATSBY_LEAGUE_PHASE,
        initalLoadComplete: true
      });
      //Get the initial phase standings based on GATSBY_LEAGUE_PHASE
      this.setInitialPhase()
    }
    //Original Rule Below
    /*if ((this.props.context.schedule !== prevProps.context.schedule) && !this.state.initialLoadComplete) {
      this.setState({
        phase: this.props.context.schedule.phases[this.props.context.schedule.phases.length - 1].title,
        initalLoadComplete: true
      });
    }*/
    
    //Scroll to active phase in phase selector
    let target = document.querySelectorAll(`.phase.selected`)
    if(target && target.length){
      target[0].scrollIntoView({behavior:'auto', block:"center"})
    }
  }

  componentWillUnmount() {
    this.updatePhase();
  }

  render() {
    return (
      <PageWrapper>
        <div className="center-wrapper">
          <div className="page-controls">
            <h1>{process.env.GATSBY_LEAGUE_HEADER_LONG} Standings</h1>
            <div className="phase-selector">
              {/* <div className={`phase ${this.state.phase === 'All' ? 'selected' : ''}`} onClick={() => this.updatePhase({title: 'All', id: ''})} key={'current selection'}>All</div> */}
              {(this.props.context.schedule && this.props.context.schedule.hasOwnProperty('phases')) && this.props.context.schedule.phases.map((phase, i) => 
                <div className={`phase ${this.state.phase === phase.title ? 'selected' : ''}`} onClick={() => this.updatePhase(phase)} key={phase.title + i}>{phase.title}</div>
              )}
            </div>
          </div>
          <div className="component-wrapper">
            {this.state.phase !== "Path to Masters" && this.state.phase !== "Road to Worlds" &&
              <StandingsModule
              season={process.env.GATSBY_LEAGUE_HEADER}
              data={this.props.context.standings && this.props.context.standings}
            />
            }
            {this.state.phase === "Path to Masters" &&
            <div className="doubleStandings">
              <StandingsModule
                season={"Order Division"}
                data={this.props.context.standings && this.props.context.standings.slice(0,4)}
              />
              <StandingsModule
                season={"Chaos Division"}
                data={this.props.context.standings && this.props.context.standings.slice(4,8)}
              />
              
              <div className="standings-subtext">• Tiebreakers will be applied in the following order: wins/losses, head-to-head record, and plus/minus. If teams are still tied after all three tiebreakers have been applied, a best-of-1 tiebreaker match will be held. </div>
            </div>
            }
            {this.state.phase === "Road to Worlds" &&
            <div className="doubleStandings">
              <StandingsModule
                season={"Order Division"}
                data={this.props.context.standings && this.props.context.standings.slice(0,4)}
              />
              <StandingsModule
                season={"Chaos Division"}
                data={this.props.context.standings && this.props.context.standings.slice(4,8)}
              />
              
              <div className="standings-subtext">• Tiebreakers will be applied in the following order: wins/losses, head-to-head record, and plus/minus. If teams are still tied after all three tiebreakers have been applied, a best-of-1 tiebreaker match will be held. </div>
            </div>
            }
            
            {this.props.context.ad !== null &&
              <VerticalPromoCard
                desktop_image={this.props.context.ad.promo_image}
                mobile_image={this.props.context.ad.promo_image}
                url={this.props.context.ad.promo_url}
              />
            }
          </div>
        </div>
      </PageWrapper>
    );
  }
}

export default withContext(Standings);
