import React from 'react';
import PropTypes from 'prop-types';
import { format, fromUnixTime } from 'date-fns';
import { ComponentWrapper } from './StandingsModule.css';
import { Link } from 'gatsby';

const StandingsModule = (props) => {
  const { data, season } = props;
	return (
    <ComponentWrapper className="c-StandingsModule">
      <div className="grid">
        <div className="header">{season}</div>
        <div className="header">Matches</div>
        <div className="header">Wins</div>
        <div className="header">Losses</div>
        <div className="header">Win %</div>
        <div className="header">+/-</div>
        <div className="header">Next Game</div>
        {data && data.map((item, index)=>
        <React.Fragment key={item.team_shortname}>
          <Link to={`/teams/${item.team_shortname.toLowerCase()}`} className="highlight team data">{item.rank}<img src={`https://webcdn.hirezstudios.com/smite-esports/dev/teams/${item.team_shortname}-70x70.png`} alt={item.team_shortname} /><span className="mobile-tag">{item.team_shortname}</span><span className="desktop-name">{item.team_name}</span></Link>
          <div className="data">{item.matches}</div>
          <div className="data">{item.wins}</div>
          <div className="data">{item.losses}</div>
          <div className="data">{item.win_percent}</div>
          <div className="highlight data total">{item.split}</div>
          <div className="data next">{item.next_game === '-' ? '-' : format(fromUnixTime(item.next_game), 'eeee, MMMM d')}</div>
        </React.Fragment>
        )}
      </div>
    </ComponentWrapper>
	); 
};

StandingsModule.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.array.isRequired
  ]),
  season: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.string.isRequired
  ])
}

export default StandingsModule;