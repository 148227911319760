import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  align-self: flex-start;
  background: var(--background);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.5);
  max-width: 118rem;
  width: 100%;
  @media only screen and (max-width: 768px) {
    margin: 0 auto;
    overflow-x: scroll;
  }
  h3 {
    color: var(--text);
    font-family: var(--font-body);
    font-size: 1.8rem;
    font-weight: bold;
  }
  a {
    text-decoration: none;
  }
  .grid {
    color: (--text);
    display: grid;    
    font-size: 2rem;
    grid-template-columns: calc(100% * (300 / 1180)) calc(100% * (140 / 1180)) calc(100% * (140 / 1180)) calc(100% * (140 / 1180)) calc(100% * (140 / 1180)) calc(100% * (140 / 1180)) auto;
    font-weight: 500;
    min-width: 66rem;
    text-align: center;
    width: 100%;
    
    .data {
      align-items: center;
      align-self: center;
      border-bottom: 1px solid var(--border);
      box-sizing: border-box;
      display: flex;
      height: 100%;
      justify-content: center;
      justify-self: center;
      padding: 1rem 0;
      width: 100%;
    }
    .next {
      text-align: left;
    }
    img {
      margin: 0 1rem 0 2rem;
      max-width: 2rem;
    }
    .highlight {
      background-color: var(--secondary-background);
      width: 100%;
      color: var(--text);
    }
    .team {
      align-items: center;
      border-bottom: 1px solid var(--border);
      display: flex;
      justify-content: flex-start;
      padding-left: 1rem;
      span {
        text-align: left;
      }
      @media only screen and (max-width: 768px) {
        min-width: 7rem;
      }
    }
    .header {
      background-color: var(--primary);
      color: var(--button-text);
      font-weight: bold;
      padding: 1rem 0;
    }
    .mobile-tag{
        display:none;
      }
    .desktop-name{
      display:flex;
    }
    @media only screen and (max-width: 768px) {
      font-size:1rem;
      min-width: 26rem;
      .mobile-tag{
        display:flex;
      }
      .desktop-name{
        display:none;
      }
       img{
        margin: 0 0.3rem 0 0.3rem;
      }
    }
  }
`;